<template>
  <div>
    <div class="row g-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header bg-white">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="card-title font-weight-semibold">Tindakan ICD 9</h6>
              <div class="form-group mb-0">
                <button @click="apiGet()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                  class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>
              </div>                  
            </div>
          </div>
          <table class="table table-bordered table-sm patient-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Tindakan Medis</th>
                <th>Diagnosa</th>
                <th>Tanggal</th>
                <th>Dokter Penanggung Jawab</th>
                <th>Inform Consent</th>
                <th>Status</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (tindakanICD9||[])" :key="k">
                <td>{{k+1}}</td>
                <td>{{v.aric_name||"-"}}</td>
                <td>
                  <a href="javascript:;" @click="lihatDiagnosa(v,k)" v-b-tooltip.click.html="v.diagnosa||'...'"
                  class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
                </td>
                <td>
                  <span v-if="v.aric_updated_date">{{v.aric_updated_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                  <span v-else>{{v.aric_created_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                </td>
                <td>
                  {{v.nama_pembuat||"-"}}
                </td>
                <td>
                  <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.aric_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                    <i class="icon-file-eye2"></i>
                  </a>
                </td>
                <td>
                  <span v-if="v.aric_status == 'DONE'" class="badge badge-success">Selesai</span>
                  <span v-else class="badge badge-info">Unggah TTD</span>
                </td>
                <td class="text-center">
                  
                  <a href="javascript:;" @click="openTemplateICUpload(v,k)" v-b-tooltip.hover title="Unggah File TTD" class="btn btn-sm btn-icon alpha-info text-info-800 border-info mb-1"><i class="icon-file-text2"></i></a>
                  
                  <a :href="v.aric_status == 'DONE' && uploader(v.aric_file)" target="_blank" v-if="v.aric_file" v-b-tooltip.hover title="Unduh TTD Dokumen" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mb-1"><i class="icon-download"></i></a>
                </td>
              </tr>
              <tr v-if="!(tindakanICD9||[]).length">
                <td class="text-center" colspan="99">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>  

    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
              {{rowIC.aric_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
              {{rowIC.aric_dasar_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
              {{rowIC.aric_tindakan_dokter||"-"}}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
              {{rowIC.aric_indikasi_tindakan||"-"}}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
              {{rowIC.aric_tata_cara||"-"}}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
              {{rowIC.aric_tujuan||"-"}}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
              {{rowIC.aric_risiko||"-"}}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
              {{rowIC.aric_kompilasi||"-"}}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
              {{rowIC.aric_prognosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
              {{rowIC.aric_alternatif||"-"}}
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
              {{rowIC.aric_lainnya||"-"}}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td width="50%" colspan="2">
              <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aric_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
              </div>
            </td>
            <td width="50%">
              <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aric_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-modal>

    <validation-observer ref="VFormUpload">
      <b-modal v-model="openTemplateICModalUpload" @ok.prevent="submitDok(rowIC,rowIC.k)" ok-title="Submit" title="Unduh Template Dokumen" size="sm">
        <div class="row">
          <div class="col-md-12">
            <a href="javascript:;" @click="openTemplateIC(rowIC)" class="btn alpha-success border-success text-success-800 mb-1">
                <b><i class="icon-download"></i></b>
                <span class="ml-2">Unduh Template Dokumen</span>
            </a>
            <Uploader v-model="rowIC.aric_file" isDocument type="doc_only" />

            <VValidate 
              name="Dokumen" 
              v-model="rowIC.aric_file" 
              :rules="{required: 1}"
            />
          </div>
        </div>
      </b-modal>
    </validation-observer>

    
    <validation-observer ref="VFormModal">
    <b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template" title="Unduh Template Dokumen" size="lg">
        <div class="col-md-12 col-12 mb-2">
          <b-form-group class="mb-0">
            <label>Dokter Pelaksana</label>
            <v-select placeholder=" -- Pilih Dokter Pelaksana -- " v-model="rowIC.dokter_pelaksana"
              :options="mDPJP" label="text" :reduce="v=>v.text"></v-select>
          </b-form-group>
        </div>

        <table class="table table-bordered table-sm text-uppercase">
            <tbody>
                <tr>
                    <td colspan="3" class="bg-primary">Data Pasien</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{patientData.ap_fullname||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Usia</h4>
                        <p>{{patientData.ap_usia_with_ket||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat</h4>
                        <p>{{patientData.ap_address||"-"}}</p>
                        </div>
                    </td>
                </tr>
                <!-- == 'PJ'-->
                <template v-if="rowIC.aric_persetujuan_tindakan == 'PJ'">
                <tr>
                    <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aric_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control"
                        placeholder="Nama Penanggung Jawab"/>
                        <VValidate 
                            name="Nama Penanggung Jawab" 
                            v-model="rowIC.aric_nama_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Usia Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aric_usia_pj" type="text" name="UsiaPenanggung" id="UsiaPenanggung" class="form-control"
                        placeholder="Usia Penanggung Jawab"/>
                        <VValidate 
                            name="Usia Penanggung Jawab" 
                            v-model="rowIC.aric_usia_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aric_address_pj" type="text" name="alamat" id="alamat" class="form-control"
                        placeholder="Alamat Penanggung Jawab"/>
                        <VValidate 
                            name="Alamat Penanggung Jawab" 
                            v-model="rowIC.aric_address_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                </tr>
                </template>
            </tbody>
        </table>
    </b-modal>
    </validation-observer>

    

  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import $ from 'jquery'
const moment = require('moment')

export default {
  extends: GlobalVue,
  data() {
    return {
      tindakanICD9 : [],
      openModalICDetail: false,
      openTemplateICModal: false,
      openTemplateICModalUpload: false,
      mDPJP: [],
      patientData: {},
      rowIC: {},
    }
  },
  props:{
    dataRow: Object,
  },
  mounted() {
    this.apiGet()
  },
  methods: {
    apiGet(){
      this.loadingOverlay = true
      Gen.apiRest(
        "/do/" + 'RoPasienTindakanICD9', {
          data: {
            type: "get-tindakan-icd-9",
            idReg: this.dataRow.ar_id,
            idPasien: this.dataRow.ar_ap_id
          }
        },
        "POST"
      ).then(resp => {
        this.loadingOverlay = false
        this.tindakanICD9 = resp.data.tindakanICD9
        this.patientData = resp.data.patientData
        this.mDPJP = resp.data.mDPJP
      })
      
    },
    lihatDiagnosa(row,k){
      Gen.apiRest(
          "/do/"+'Dashboard',
          {data:{type:'lihat-diagnosa', id: row.aric_ar_id}}, 
          "POST"
      ).then(res=>{
        this.tindakanICD9[k]['diagnosa'] = res.data        
      })
    },
    openInformConsentDetail(v){
      Gen.apiRest('/do/' + 'RekamMedis', {
        data: {
            type: 'get-data-ic',
            id: v.aric_id,
        }
      }, 'POST').then(res => {
        this.rowIC = res.data.row 
        this.rowIC.mi9_name = v.mi9_name || v.aric_name || null
        this.rowIC.mi9_code = v.mi9_code
        this.rowIC.id_icd = v.aric_id
        this.openModalICDetail = true                   
      })
    },
    uploader(filename, def="/assets/images/user-placeholder.svg") {
      def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL +def : def
      if(!filename){
        return def
      }
      return this.apiUrl + '/uploader/' + filename
    },
    openTemplateICUpload(v,k){
        this.rowIC = v
        this.rowIC.k = k
        this.rowIC.aric_nama_pj = this.rowIC.aric_nama_pj||this.patientData.arpj_fullname
        this.rowIC.aric_address_pj = this.rowIC.aric_address_pj||this.patientData.arpj_address
        this.rowIC.aric_usia_pj = this.rowIC.aric_usia_pj||this.patientData.ap_usia_with_ket
        this.openTemplateICModalUpload = true
    },
    
    openTemplateIC(v){
        this.rowIC = v
        this.rowIC.aric_nama_pj = this.rowIC.aric_nama_pj||this.patientData.arpj_fullname
        this.rowIC.aric_address_pj = this.rowIC.aric_address_pj||this.patientData.arpj_address
        this.rowIC.aric_usia_pj = this.rowIC.aric_usia_pj||this.patientData.ap_usia_with_ket
        this.openTemplateICModal = true
        this.$set(this.rowIC, 'dokter_pelaksana', this.rowIC.dokter_pelaksana||this.dataRow.bu_full_name)
    },

  
    uploadDok(v,k=''){
      Gen.apiRest('/do/' + 'RoPasienTindakanICD9', {
          data: {
              type: 'update-data-ic',
              id: v.aric_id,
              aric_file: v.aric_file,
              aric_nama_pj: v.aric_nama_pj,
              aric_usia_pj: v.aric_usia_pj,
              aric_address_pj: v.aric_address_pj,
          }
      }, 'POST').then(res => {
          if(k){
              this.tindakanICD9[k]['aric_status'] = 'DONE'
              this.openTemplateICModalUpload = false 
          }
      })
    },

    unduhtemplateIC(v){
      this.$refs['VFormModal'].validate().then(success => {
        if(success){
            this.uploadDok(v)                
            setTimeout(()=>{
                this.openTemplateICModal = false
                let data = {exptype: 'xlsx', type: "export", id: v.aric_id, regID: v.aric_ar_id, dokter_pelaksana: v.dokter_pelaksana}
                let self = this
                self.loadingOverlay = true
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsentRajal'}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${'Inform-consent'}-${this.patientData.ap_fullname}-${moment().format("YYYY-MM-DD")}-${this.dataRow.ar_reg_code}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },2000)
            
        }
      })
    },
    
    submitDok(v,k){
      this.$refs['VFormUpload'].validate().then(async success => {
        if(success){
          await this.uploadDok(v,k)
          this.openTemplateICModalUpload = false
          this.$swal.fire({
            title: "Inform Consent Berhasil Diunggah",
            icon: "success",              
          })
          this.apiGet()
        }
      })
    },
    
  }
}
</script>